import Mustache from 'mustache';
import {ICommentaireLimited} from "../../src/types/view.js";
import {openDialog} from "./dialog_popin.js";

window.addEventListener("DOMContentLoaded", () => {
	const $mustache = [...document.querySelectorAll<HTMLElement>('[data-mustache]')];
	for (let $el of $mustache) {
		const data = JSON.parse($el.dataset.mustache ?? '{}');
		$el.innerHTML = Mustache.render($el.innerHTML, data);
		$el.removeAttribute('data-mustache');
	}
});

function onToggle(e:Event) {
	const bp : HTMLDetailsElement | null = (<HTMLDetailsElement>e.target).closest("details.bp");
	if (!bp) return;

	if (bp.open) {
		const $mustache = [...bp.querySelectorAll<HTMLElement>('[data-mustache-lazy]')];
		for (let $el of $mustache) {
			const data = JSON.parse($el.dataset.mustacheLazy ?? '{}');
			$el.insertAdjacentHTML('afterend', Mustache.render($el.innerHTML, data));
			$el.remove();
		}
	}
}
for (let details of document.querySelectorAll('details.bp')) {
	details.addEventListener('toggle', onToggle);
}



document.addEventListener("click", (e) => {
	const questionBtn = (<HTMLElement>e.target).closest<HTMLAnchorElement>("a[href='#pop_question']");
	if (!questionBtn) return;

	e.preventDefault();
	renderQuestion(questionBtn);
	location.hash = '#pop_question';
});

document.addEventListener("click", (e) => {
	const partageBtn = (<HTMLElement>e.target).closest<HTMLAnchorElement>("a[href='#pop_mail']");
	if (!partageBtn) return;

	e.preventDefault();
	renderPartage();
	location.hash = '#pop_mail';
});

document.addEventListener("click", (e) => {
	const commentBtn = (<HTMLElement>e.target).closest<HTMLAnchorElement>("a[href='#pop_comment']");
	if (!commentBtn) return;

	e.preventDefault();
	renderComment(commentBtn);
	location.hash = '#pop_comment';
});

function renderQuestion(questionBtn: HTMLElement) {
	const template_question = document.querySelector<HTMLElement>('#template_question');
	if (template_question) {
		const rendered = Mustache.render(template_question.innerHTML, {
			"category_title": questionBtn.dataset.catTitle,
			"bp_num": questionBtn.dataset.bpNum,
			"timestamp": Date.now(),
			"message_subject": questionBtn.dataset.subject
		});
		const target_question = document.querySelector<HTMLElement>('#question');
		if (target_question !== null) {
			target_question.dataset.reportId = template_question.dataset.reportId;
			target_question.innerHTML = rendered;
		}
	}
}

function renderPartage() {
	const template_partage = document.querySelector<HTMLElement>('#template_partage');
	if (template_partage) {
		const rendered = Mustache.render(template_partage.innerHTML,
			{
				"timestamp": Date.now()
			});
		const target_partage = document.querySelector<HTMLElement>('#mail');
		if (target_partage !== null) {
			target_partage.dataset.reportId = template_partage.dataset.reportId;
			target_partage.innerHTML = rendered;
		}
	}
}

type ExtendedWindow = Window & typeof globalThis & {
	commentaires?: ICommentaireLimited[]
}
const win : ExtendedWindow = window;
function renderComment(commentBtn: HTMLElement) {
	if (!commentBtn.dataset.comment || !win.commentaires) {
		throw new Error('MISSING_DATA_COMMENT');
	}

	const template_comment = document.querySelector('#template_comment')?.innerHTML;
	if (!template_comment) {
		throw new Error('MISSING_COMMENT_TEMPLATE');
	}

	const _data = JSON.parse(commentBtn.dataset.comment);
	const commentaires = win.commentaires.filter(commentaire => {
		return commentaire.id === _data.id
	});

	const rendered = Mustache.render(template_comment, {
		comment: commentaires.at(0),
		catId: _data.catId
	});
	const target_comment = document.querySelector<HTMLElement>('#comment');
	if (target_comment !== null) {
		target_comment.innerHTML = rendered;
	}
}
